import { FunctionComponent } from "react";
import styles from "./Parameters.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Parameters: FunctionComponent = () => {

  const navigate = useNavigate();


  function moveToPlayStore(){
    window.open('https://play.google.com/store/apps/details?id=com.sharatsawhney.prevealth','_blank')
  }
  function moveToAppStore(){
    window.open('https://www.apple.com/in/search/prevealth','_blank')
  }

  function nav(){
    navigate('/')
  }
  return (
    <div className={styles.website}>
      <Header></Header>
      <div className={styles.banner}>
        <img src="/images/tc.png" />
        <div className={styles.title}>Report Parameters</div>
      </div>
      <div className={styles.main}>
        <div className={styles.mainItem}>
          <div className={styles.mainTop}>
            <div className={styles.mainImage}>
              <img src="/icons/param6.svg" />
            </div>
            <div className={styles.mainHead}>
              <p>Ancestry: GENEroots</p>
            </div>

          </div>
          <div className={styles.mainBottom}>
            <Accordion className={styles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Ancestory and genes</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aItem}>
                  <img src="/icons/params/ancestry1.svg" />
                  <p>Ancestory and Roots</p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={styles.mainItem}>
          <div className={styles.mainTop}>
            <div className={styles.mainImage}>
              <img src="/icons/param1.svg" />
            </div>
            <div className={styles.mainHead}>
              <p>Diet and Nutrition</p>
            </div>

          </div>
          <div className={styles.mainBottom}>
            <Accordion className={styles.accordion2}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Food Preferences</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet5.svg" />
                    <p>Carbohydrate preference</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet1.svg" />
                    <p>Fruit Diet Preference</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet2.svg" />
                    <p>Mediterranian Diet Effectiveness</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet3.svg" />
                    <p>Fat Over-consumption</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet4.svg" />
                    <p>Raw Vegetable preference</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet6.svg" />
                    <p>Over Eating risk</p>
                  </div>
                </div>
                <p className={styles.more1}>+8 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Metabolic Rate Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet7.svg" />
                    <p>Choline Metabolism</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet8.svg" />
                    <p>Lipid Metabolism</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet9.svg" />
                    <p>Alcohol Flush</p>
                  </div>
                </div>
                <div className={styles.aGroup2}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet10.svg" />
                    <p>Vitamin A Metabolism</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet11.svg" />
                    <p>Caffeine consumption</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Food Sensitivity</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet12.svg" />
                    <p>Caffeine sensitivity</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet13.svg" />
                    <p>Trans Fats Sensitivity</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet14.svg" />
                    <p>Lactose Sensitivity</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet15.svg" />
                    <p>Cortisol Sensitivity</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet16.svg" />
                    <p>Bitter taste Sensitivity</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet17.svg" />
                    <p>Salt sensitivity</p>
                  </div>
                </div>
                <p className={styles.more1}>+2 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Vitamin Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet18.svg" />
                    <p>Vitamin A</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet19.svg" />
                    <p>Folic Acid</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet20.svg" />
                    <p>Vitamin B2</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet21.svg" />
                    <p>Vitamin B9</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet22.svg" />
                    <p>Vitamin B12</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet23.svg" />
                    <p>Vitamin C</p>
                  </div>
                </div>
                <p className={styles.more1}>+5 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Protein Profile</Typography>
              </AccordionSummary>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Fat Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet24.svg"/>
                    <p>Low Fat Diet Effectiveness</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet25.svg" />
                    <p>Polyunsaturated fat</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet26.svg" />
                    <p>Omega 3 & Omega 6</p>
                  </div>
                </div>
                <div className={styles.aGroup2}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet27.svg" />
                    <p>Monosaturated fat</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet28.svg" />
                    <p>Total Fat</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Mineral Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet29.svg" />
                    <p>Calcium</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet30.svg" />
                    <p>Magnesium</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet31.svg" />
                    <p>Iron</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet32.svg" />
                    <p>Copper</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet33.svg" />
                    <p>Sodium</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/diet34.svg" />
                    <p>Selenium</p>
                  </div>
                </div>
                <p className={styles.more1}>+1 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion className={styles.accordion3}>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Antioxidant Profile</Typography>
              </AccordionSummary>
            </Accordion>
          </div>
        </div>
        <div className={styles.mainItem}>
          <div className={styles.mainTop}>
            <div className={styles.mainImage}>
              <img src="/icons/param2.svg" />
            </div>
            <div className={styles.mainHead}>
              <p>Fitness</p>
            </div>

          </div>
          <div className={styles.mainBottom}>
            <Accordion className={styles.accordion2}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Genetic Fitness Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit1.svg" />
                    <p>Aerobic Potential</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit2.svg" />
                    <p>Detoxification Ability</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit3.svg" />
                    <p>Muscle strength</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit4.svg" />
                    <p>Affinity to Sedentary lifestyle</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit5.svg" />
                    <p>DNA Damage Repair</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit6.svg" />
                    <p>Power</p>
                  </div>
                </div>
                <p className={styles.more2}>+4 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Exercise Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit7.svg" />
                    <p>Benefit of brisk walking</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit8.svg" />
                    <p>Response to exercise on BP</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit9.svg" />
                    <p>Aversion to Exercise</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit10.svg" />
                    <p>Motivation to Exercise</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit11.svg" />
                    <p>Response to exercise on weight</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit12.svg" />
                    <p>Response To Exercise On Cholesterol</p>
                  </div>
                </div>
                <p className={styles.more2}>+2 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Injury susceptibility Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit13.svg" />
                    <p>Ankle Injury Susceptibility</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit14.svg" />
                    <p>Risk of Ligament Rupture</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit15.svg" />
                    <p>Tendency of sports Injury</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Sleep Pattern Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit16.svg" />
                    <p>Circadian rhythm</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit17.svg" />
                    <p>Insomnia</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit18.svg" />
                    <p>Sleep Health</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit19.svg" />
                    <p>Sleep Apnea</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Weight Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit20.svg"/>
                    <p>Body Fat Percentage</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit21.svg" />
                    <p>Waist Circumference response</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit22.svg" />
                    <p>Weight Gain Tendency</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit23.svg" />
                    <p>Difficulty in losing weight</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit24.svg" />
                    <p>BMI</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit25.svg" />
                    <p>Obesity</p>
                  </div>
                </div>
                <p className={styles.more2}>+1 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion className={styles.accordion3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Food Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit26.svg" />
                    <p>Satiety Impairment</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit27.svg" />
                    <p>High Protein Diet Effectiveness</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/fit28.svg" />
                    <p>Low carbohydrate Diet Effectiveness</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={styles.mainItem}>
          <div className={styles.mainTop}>
            <div className={styles.mainImage}>
              <img src="/icons/param3.svg" />
            </div>
            <div className={styles.mainHead}>
              <p>Lifestyle</p>
            </div>

          </div>
          <div className={styles.mainBottom}>
            <Accordion className={styles.accordion2}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Habits and Preferences: Food Pattern Behaviour</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life1.svg" />
                    <p>Carbohydrate preference</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life2.svg" />
                    <p>Mediterranian Diet Effectiveness</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life3.svg" />
                    <p>Affinity to smoking</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life4.svg" />
                    <p>Fat Over-consumption</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life5.svg" />
                    <p>Red Wine Preference</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life6.svg" />
                    <p>Fruit Diet Preference</p>
                  </div>
                </div>
                <p className={styles.more3}>+8 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion className={styles.accordion3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Fitness Behavior</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life7.svg" />
                    <p>Affinity to Sedentary lifestyle</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life8.svg" />
                    <p>Likelihood of Fatigue</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life9.svg" />
                    <p>Smoking</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life10.svg" />
                    <p>Childhood Obesity</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life11.svg" />
                    <p>Motivation to Exercise</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/life12.svg" />
                    <p>Obesity</p>
                  </div>
                </div>
                <p className={styles.more3}>+2 more</p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={styles.mainItem}>
          <div className={styles.mainTop}>
            <div className={styles.mainImage}>
              <img src="/icons/param4.svg" />
            </div>
            <div className={styles.mainHead}>
              <p>Health Predisposition</p>
            </div>

          </div>
          <div className={styles.mainBottom}>
            <Accordion className={styles.accordion2}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Cardiovascular Predisposition</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health1.svg" />
                    <p>Calcific Aortic Valve Stenosis</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health2.svg" />
                    <p>Coronary Artery Disease</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health3.svg" />
                    <p>Hypertension</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health4.svg" />
                    <p>Coronary artery calcification</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health5.svg" />
                    <p>Elevated Triglycerides</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health6.svg" />
                    <p>Myocardial Infarction</p>
                  </div>
                </div>
                <p className={styles.more4}>+2 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Diabetes Predisposition</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health7.svg" />
                    <p>Blood Glucose</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health8.svg" />
                    <p>Type 1 Diabetes</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health9.svg" />
                    <p>Type 2 Diabetes</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Cancer Predisposition</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health10.svg" />
                    <p>Breast Cancer</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health11.svg" />
                    <p>Ovarian Cancer</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health12.svg" />
                    <p>Testicular Cancer</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={styles.accordion3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Recreational Drug Predisposition</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health13.svg" />
                    <p>Alcohol</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health14.svg" />
                    <p>Opioids</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health15.svg" />
                    <p>Cocaine</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health16.svg" />
                    <p>Heroin</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health17.svg" />
                    <p>Cannabis</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/health18.svg" />
                    <p>Nicotine</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={styles.mainItem}>
          <div className={styles.mainTop}>
            <div className={styles.mainImage}>
              <img src="/icons/param5.svg" />
            </div>
            <div className={styles.mainHead}>
              <p>Personality</p>
            </div>

          </div>
          <div className={styles.mainBottom}>
            <Accordion className={styles.accordion2}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Cognitive Ability</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per1.svg" />
                    <p>Academics</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per2.svg" />
                    <p>Numerical Abilities</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per3.svg" />
                    <p>General Mental Ability</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per4.svg" />
                    <p>Arithmetic Abilities</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per5.svg" />
                    <p>Rapid Automatised Naming</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per6.svg" />
                    <p>Reading Aptitude</p>
                  </div>
                </div>
                <p className={styles.more5}>+1 more</p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Cognitive Behavior</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per7.svg" />
                    <p>Dominant Hand</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per8.svg" />
                    <p>Spatial Memory</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per9.svg" />
                    <p>Working Memory</p>
                  </div>
                </div>
                <div className={styles.aGroup2}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per10.svg" />
                    <p>Social Interaction Ability</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per11.svg" />
                    <p>Verbal Memory</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={styles.accordion3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.aHead}
              >
                <Typography className={styles.aHeadText}>Emotional Ability</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per12.svg" />
                    <p>Adventurous</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per13.svg" />
                    <p>Panic Attack</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per14.svg" />
                    <p>Hurt</p>
                  </div>
                </div>
                <div className={styles.aGroup}>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per15.svg" />
                    <p>Anger</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per16.svg" />
                    <p>Resillience</p>
                  </div>
                  <div className={styles.aItem}>
                    <img src="/icons/params/per17.svg" />
                    <p>Irritated</p>
                  </div>
                </div>
                <p className={styles.more5}>+10 more</p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Parameters;

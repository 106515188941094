import { FunctionComponent, useState, useEffect } from "react";
import styles from "./Plans.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from 'axios';


const Plans: FunctionComponent = () => {

  const navigate = useNavigate();
  
  let [gata,setGata] = useState<string[]>([]); 
  const [rCount,setRCount] = useState(100)

  const url = "https://prevealth.com/v1/api/test/getAllTests";
  const headers = {
    'Content-Type':'application/json',
    'x-administrator-token':'reta245'
  }
  const images = ["/arts/plans2.png","/arts/plans1.png","/arts/plans3.png","/arts/plans4.png",
  "/arts/plans5.png","/arts/plans6.png","/arts/plans7.png","/arts/plans8.png",
  "/arts/plans9.png","/arts/plans10.png","/arts/plans11.png","/arts/plans12.png",
  "/arts/plans5.png"]


  const fetchInfo = () => {
    return axios.post(url,{},{headers:headers}).then((response) => {
      let mata = response.data.data.sort((a:any,b:any)=>a.order-b.order);
      let jata = Array();
      let kata = Array();
      for(let i=0;i<mata.length;i++){
        let h1 = Array();
        for(var h=0;h<mata[i]['highlights1'].length;h++){
          h1.push(<li>{mata[i]['highlights1'][h]}</li>)
        }
        let h2 = Array();
        for(var h=0;h<mata[i]['highlights2'].length;h++){
          h2.push(<li>{mata[i]['highlights2'][h]}</li>)
        }
        kata.push( 
          <div className={styles.planItem}>
            <div className={styles.itemLeft}>
              <img src={images[mata[i]['order']-1]} />
            </div>
            <div className={styles.itemRight}>
              <h6>{mata[i]['name']}</h6>
              <div>
                <p>{mata[i]['highlightsTitle1']}</p>
                <ul>
                 {h1}
                </ul>
              </div>
              <div>
                <p>{mata[i]['highlightsTitle2']}</p>
                <ul>
                 {h2} 
                </ul>
              </div>
            </div>
          </div>
          );
        if((i-2)%3==0 || i==mata.length-1){
          jata.push(
            <div className={styles.planFlex}>
              {kata}
            </div>
          )
          kata = Array();
        }

      }
      setGata(jata)
    })
  }

  useEffect(() => {
    fetchInfo();
    setRCount(100)
  },[rCount])


  function moveToPlayStore(){
    window.open('https://play.google.com/store/apps/details?id=com.sharatsawhney.prevealth','_blank')
  }
  function moveToAppStore(){
    window.open('https://www.apple.com/in/search/prevealth','_blank')
  }

  function navParameters(){
    navigate('/parameters')
  }
  return (
    <div className={styles.website}>
      <Header></Header>
      <div className={styles.banner}>
        <img src="/images/tc.png" />
        <div className={styles.title}>Plans</div>
      </div>
      <div className={styles.main}>
        {gata}
      </div>
      <div className={styles.params}>
        <h2 className={styles.heading}>Featured Report Parameters</h2>
        <div className={styles.parameters}>
          <div className={styles.paramFlex}>
            <div className={styles.paramItem}>
              <img src="/icons/param1.svg" className={styles.mainIcon} />
              <h6>Diet and Nutrition</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/diet5.svg" />
                <p>Carbohydrate preference</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet1.svg" />
                <p>Fruit Diet Preference</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet2.svg" />
                <p>Mediterranian Diet Effectiveness</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet3.svg" />
                <p>Fat Over-consumption</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet4.svg" />
                <p>Raw Vegetable preference</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet6.svg" />
                <p>Over Eating risk</p>
              </div>
              <p className={styles.more} onClick={navParameters}>Explore more parameters</p>
            </div>
            <div className={styles.paramItem}>
              <img src="/icons/param2.svg" className={styles.mainIcon} />
              <h6>Fitness</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/fit1.svg" />
                <p>Aerobic Potential</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit2.svg" />
                <p>Detoxification Ability</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit3.svg" />
                <p>Muscle strength</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit4.svg" />
                <p>Affinity to Sedentary lifestyle</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit5.svg" />
                <p>DNA Damage Repair</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit6.svg" />
                <p>Power</p>
              </div>
              <p className={styles.more2} onClick={navParameters}>Explore more parameters</p>
            </div>
            <div className={styles.paramItem}>
              <img src="/icons/param3.svg" className={styles.mainIcon} />
              <h6>Lifestyle</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/life7.svg" />
                <p>Affinity to Sedentary lifestyle</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life8.svg" />
                <p>Likelihood of Fatigue</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life9.svg" />
                <p>Smoking</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life10.svg" />
                <p>Childhood Obesity</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life11.svg" />
                <p>Motivation to Exercise</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life12.svg" />
                <p>Obesity</p>
              </div>
              <p className={styles.more3} onClick={navParameters}>Explore more parameters</p>
            </div>
          </div>
          <div className={styles.paramFlex2}>
            <div className={styles.paramItem}>
              <img src="/icons/param4.svg" className={styles.mainIcon} />
              <h6>Health Predisposition</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/health1.svg" />
                <p>Calcific Aortic Valve Stenosis</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health8.svg" />
                <p>Type 1 Diabetes</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health9.svg" />
                <p>Type 2 Diabetes</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health10.svg" />
                <p>Breast Cancer</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health11.svg" />
                <p>Ovarian Cancer</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health15.svg" />
                <p>Cocaine</p>
              </div>
              <p className={styles.more4} onClick={navParameters}>Explore more parameters</p>
            </div>
            <div className={styles.paramItem}>
              <img src="/icons/param5.svg" className={styles.mainIcon} />
              <h6>Personality</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/per2.svg" />
                <p>Numerical Abilities</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per3.svg" />
                <p>General Mental Ability</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per8.svg" />
                <p>Spatial Memory</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per10.svg" />
                <p>Social Interaction Ability</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per15.svg" />
                <p>Anger</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per16.svg" />
                <p>Resillience</p>
              </div>
              <p className={styles.more5} onClick={navParameters}>Explore more parameters</p>
            </div>
          </div>
          <div className={styles.paramBtn} onClick={navParameters}>See All Report Parameters</div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Plans;

import React, { FunctionComponent,useState, useEffect } from "react";
import styles from "./Blogs.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer"
import axios from 'axios';


const Blogs: FunctionComponent = () => {

  const navigate = useNavigate();
  
  let [gata,setGata] = useState<string[]>([]); 
  let [main,setMain] = useState<string[]>([]); 
  const [rCount,setRCount] = useState(100)

  const url = "https://prevealth.com/v1/api/blog/getBlogAll";
  const headers = {
    'Content-Type':'application/json',
    'x-administrator-token':'reta245'
  }


  const fetchInfo = () => {
    return axios.post(url,{},{headers:headers}).then((response) => {
      let mata = response.data.data;
      let jata = Array();
      for(let i=0;i<mata.length;i++){
        if(mata[i]['order'] != 1){
          jata.push(
            <div className={styles.blogItem} key={mata[i]['order']}>
            <div className={styles.mainBlog2} style={{backgroundImage:`url(/images/blogs/`+mata[i]['image']+`)`}}>
              </div>
              <div className={styles.mainBody2}>
                <div className={styles.tagRed}>{mata[i]['tag']}</div>
                <p className={styles.main1}>{mata[i]['heading']}</p>
                <p className={styles.main2}>{mata[i]['description']}</p>
                <div className={styles.extra}>
                  <span onClick={() => navigate('/blog_body?order='+mata[i]['order'])}>Read more</span>
                  <span>{mata[i]['read']} min read</span>
                </div>
              </div>
          </div>
          );
        }else{
          let vata = Array();
          vata.push(<div className={styles.mainBlog} style={{backgroundImage:`url(/images/blogs/`+mata[i]['image']+`)`}}>
            <div className={styles.mainBody}>
              <div className={styles.tagBlue}>{mata[i]['tag']}</div>
              <p className={styles.main1}>{mata[i]['heading']}</p>
              <p className={styles.main2}>{mata[i]['description']}</p>
              <div className={styles.extra}>
                <span onClick={() => navigate('/blog_body?order='+mata[i]['order'])}>Read more</span>
                <span>{mata[i]['read']} min read</span>
              </div>
            </div>
          </div>)
          setMain(vata)
        }
      }
      setGata(jata)
    })
  }

  useEffect(() => {
    fetchInfo();
    setRCount(100)
  },[rCount])

  return (
    <div className={styles.website}>
      <Header></Header>
      <div className={styles.banner}>
        <img src="/images/tc.png" />
        <div className={styles.title}>Blogs</div>
      </div>
      {main}
      <div className={styles.blogs}>
        {gata}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Blogs;

import { FunctionComponent } from "react";
import styles from "./PrivacyPolicy.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer"


const PrivacyPolicy: FunctionComponent = () => {

  const navigate = useNavigate();


  function moveToPlayStore(){
    window.open('https://play.google.com/store/apps/details?id=com.sharatsawhney.prevealth','_blank')
  }
  function moveToAppStore(){
    window.open('https://www.apple.com/in/search/prevealth','_blank')
  }

  function nav(){
    navigate('/')
  }
  return (
    <div className={styles.website}>
      <Header></Header>
      <div className={styles.banner}>
        <img src="/images/tc.png" />
        <div className={styles.title}>Privacy Policy</div>
      </div>
      <div className={styles.core}>
        <p><b>Privacy Policy- Helix Prevealth Healthtech Pvt. Ltd.</b></p>
        <p>
        1.	Definitions
        2.	Introduction
        3.	Information collected
        4.	How Information is collected
        5.	Using information collected
        6.	Disclosure of information
        7.	Third-Party sites
        8.	Grievance officer
        9.	General
        </p>
        <p><b>1. Definitions</b></p>
        <p>

        In this Privacy Policy, unless the context otherwise requires-
        ‘Information’ means all information and data collected from the Users by Prevealth, including personal information.
        ‘Prevealth’ means M/s. Helix Prevealth Healthtech Private Limited;
        ‘Personal information’ means the information provided in Rule 3 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. 
        Provided that any information that is publicly available or accessible or furnished under the Right to Information Act, 2005 or any other law shall not be deemed to be deemed as ‘personal information’;
        ‘Privacy Policy’ means the present document containing the details regarding collection, disclosure and treatment of personal information of the Users. It also includes any subsequent changes made by Prevealth in this Privacy Policy;
        ‘Terms of Use’ means the MobileApp / Website’s terms and conditions provided at the MobileApp / Website
        ‘User’ means the visitor or person using/navigating the MobileApp / Website as well as a customer of Prevealth who is using/navigating the MobileApp / Website;
        ‘Website’ is the online web portal of Prevealth having the web link at https://Prevealth.com/. 
        ‘Mobile App’ is the online mobile portal of Prevealth
        </p>
        <p><b>2. Introduction</b></p>
        <p>
        Prevealth recognizes the importance of maintaining the User’s privacy. This Privacy Policy describes how Prevealth treats the User’s information collected on the MobileApp / Website. This Privacy Policy applies to current and former Users to our MobileApp / Website and to our online customers that are using the MobileApp / Website. By visiting and/or using our MobileApp / Website, the User agrees to this Privacy Policy and the terms and conditions herein. In case the User disagrees with any of the terms and conditions herein, then the User shall not be allowed to visit and/or use the MobileApp / Website.
        The Privacy Policy are formulated pursuant to the Information Technology Act, 2000 and its various rules (as amended), such as the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 and the Information Technology (Intermediaries guidelines) Rules, 2011 and any other applicable laws in India.
        As per Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011, the present Privacy Policy is published on the MobileApp / Website of Prevealth and lists the types of personal information collected, the purpose and usage of such personal information, authorized disclosure of such personal information and the reasonable security practices and procedures to protect personal information of the User. 
        This Privacy Policy does not apply to the MobileApp / Websites of our business partners, corporate affiliates or to any other third parties, even if their MobileApp / Websites are linked to the site. The User acknowledges and affirms that Prevealth shall not liable in case any information is unlawfully or unauthorizedly disclosed/misused by Prevealth business partners, corporate affiliates or any other third-party.
        </p>
        <p><b>3. Information collected</b></p>
        <p>
        For the User to gain access and use the MobileApp / Website, Prevealth may collect personal information as well as non-personal and other information.
        To provide the User services and access to MobileApp / Website, Prevealth collect-
        Personal information that includes amongst other things, name, age, sex, medical data. 
        Non-personal information that includes information about the user from third-party APIs such as Google Analytics, IP address, browser, cookies, shared contents and more which may be collected over time.
        Prevealth reserve the right to collect any personal, non-personal and other information of the User for access and use of the MobileApp / Website or to provide the User any services. In such case, the User shall be informed of the information so collected by Prevealth. In case the User opts out of sharing such information with Prevealth, the User would not be allowed to gain access and use the MobileApp / Website or enjoy any service. 
        Prevealth will retain any information collected from its Users as long as needed or permitted in light of the purpose for which it was obtained and consistent with applicable law. Prevealth reserve the right to retain any information collected from its Users to comply with its legal obligations, resolution of disputes and enforce legal agreements and policies. Prevealth also reserves the right to retain any information collected from its Users for internal analysis purposes. In case the User wishes to withdraw the information retained by Prevealth, he shall duly inform Prevealth in writing in email (contact@prevealth.com).
        All information shall be protected by Prevealth with all the reasonable security measures and practices from unauthorized sources, maintain data accuracy and help ensure the appropriate use/disclosure of information as required under this Privacy Policy and law. The User acknowledges and affirms that in spite of the excellent security measures taken by Prevealth to protect any information, Prevealth does not guarantee of such information’s absolute security.
        </p>
        <p><b>4. How Information is collected</b></p>
        <p>
        Prevealth may collect any information about the User to provide its services and products. This can be done through the information given by the User directly while accessing or availing MobileApp / Website, emails, customer engagements, mobile app and other products that are required for smooth functioning of product and delivery of services.
        Additionally, Prevealth may collect information about the User passively through direct and third-party analytics services.
        Moreover, Prevealth may collect any information about the User through third-parties.
        </p>
        <p><b>5. Using information collected</b></p>
        <p>
        Prevealth may use the information so collected from its User:
        To provide its services and products to the User or any other person;
        To provide customer support and handle any complaints or feedback;
        To monitor the usage of services and product of Prevealth;
        To provide you with news, special offer and general information about other goods and services and events that Prevealth offer and are similar to those already availed
        Provided that Prevealth shall not use the information for such purpose in case the User has opted to not receive any updates.
        To respond to any question, requests or comment made by the User or any other person;
        To further improve on the products and services and to further customize them;
        To make the MobileApp / Website better and combine information provided by the User and information about the User from third-parties;
        To protect Prevealth, its directors, employees, agents, customers, MobileApp / Website and any product and services which Prevealth provide;
        To provide special information or for marketing purposes;
        For transactional purposes, and;
        As otherwise permitted or required under law.
        </p>
        <p><b>6. Disclosure of information</b></p>
        <p>
        Prevealth may share/transfer/assign any information (including personal information) collected from its Users:
        With vendors and retailers who help Prevealth in providing its products and services to its customers;
        With event organizers and other parties responsible for fulfilling Prevealth’ obligation towards its Users;
        With business partners of Prevealth including third-parties who provide or sponsor an event, or who operates a venue where Prevealth hold events. These third-parties may utilize the information so received according to their respective Privacy Policy;
        With the law enforcement agencies, judicial bodies, government bodies or any other public office as requested and required under law;
        With the successor of Prevealth;
        In case Prevealth share/transfer/assign any information (including personal information) with persons not provided in Clause 6.1., it will inform the User of the disclosure of information while stating the reasons behind the same and allowing an option to the User to deny such disclosure by Prevealth. In case the User denies such disclosure, Prevealth shall reserve its right to provide services to the User.
        </p>
        <p><b>7. Third-Party sites</b></p>
        <p>
        In case the User clicks any third-party webpage’s link, it will be directed to that particular webpage which is not under control and supervision of Prevealth. Privacy Policy will not apply to those webpages which would be governed by the respective third-party’s Privacy Policy.
        Prevealth in any way whatsoever shall not be responsible for these third-party MobileApp / Websites. The User acknowledges that in case of breach of privacy by a third-party MobileApp / Website, Prevealth shall not be held liable for the same.
        </p>
        <p><b>8. Grievance officer</b></p>
        <p>
        In pursuant to the Information Technology Act, 2000 and its allied rules, the User may get in touch with the grievance committee of the Prevealth available at contact@prevealth.com.  
        </p>
        <p><b>9. General</b></p>
        <p>
        Privacy Policy was last updated on 01.11.2023. From time to time, Prevealth may change the Privacy Policy privacy in which case, the User will be notified of any material changes as required by law. Prevealth will also post an updated copy on the MobileApp / Website. Please check the MobileApp / Website periodically for updates.
        If the User chooses to visit the MobileApp / Website, its visit and any dispute over privacy is subject to the Privacy Policy and the Terms of Use. In addition to the foregoing, any disputes arising under the Privacy Policy shall be governed by the laws of India and the courts of New Delhi shall have exclusive jurisdiction to adjudicate disputes arising out of the Privacy Policy.

        </p>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PrivacyPolicy;

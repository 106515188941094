import { FunctionComponent, useEffect } from "react";
import styles from "./About.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer"
import {
  isAndroid,
  isIOS
} from "react-device-detect";

const Redirect: FunctionComponent = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.sharatsawhney.prevealth";
    }else if(isIOS) {
      window.location.href =
        "https://apps.apple.com/in/app/prevealth/id6470473274";
    } else{
      window.location.href =
        "https://prevealth.com/";
    }
  }, []);


 
  return (
    <div></div>
  );
};

export default Redirect;

import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Website from "./pages/Website";
import BlogBody from "./pages/BlogBody";
import About from "./pages/About";
import Blogs from "./pages/Blogs";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Plans from "./pages/Plans";
import Parameters from "./pages/Parameters";
import Redirect from "./pages/Redirect";
import ContactDiet from "./pages/ContactDiet";
import Reports from "./pages/Reports";
import DeleteUser from "./pages/DeleteUser";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "DNA-Testing based Healthcare";
        metaDescription = "DNA-Testing based Healthcare";
        break;
      case "/blog_body":
        title = "Blogs | DNA-Testing based Healthcare";
        metaDescription = "Blogs | DNA-Testing based Healthcare";
        break;
      case "/about":
        title = "About | DNA-Testing based Healthcare";
        metaDescription = "About | DNA-Testing based Healthcare";
        break;
      case "/blogs":
        title = "Blogs | DNA-Testing based Healthcare";
        metaDescription = "Blogs | DNA-Testing based Healthcare";
        break;
      case "/terms_conditions":
        title = "Terms & Conditions | DNA-Testing based Healthcare";
        metaDescription = "Terms & Conditions | DNA-Testing based Healthcare";
        break;
      case "/delete_account":
          title = "Delete user ";
          metaDescription = "Delete user";
          break;
      case "/privacy_policy":
        title = "Privacy Policy | DNA-Testing based Healthcare";
        metaDescription = "Privacy Policy | DNA-Testing based Healthcare";
        break;
      case "/plans":
        title = "Plans | DNA-Testing based Healthcare";
        metaDescription = "Plans | DNA-Testing based Healthcare";
        break;
      case "/parameters":
        title = "Parameters | DNA-Testing based Healthcare";
        metaDescription = "Parameters | DNA-Testing based Healthcare";
        break;
      case "/redirect":
        title = "DNA-Testing based Healthcare";
        metaDescription = "DNA-Testing based Healthcare";
        break;
      case "/contact-diet":
        title = "Contact | DNA-Testing based Healthcare";
        metaDescription = "Contact | DNA-Testing based Healthcare";
        break;
      case "/reports":
        title = "Reports | DNA-Testing based Healthcare";
        metaDescription = "Reports | DNA-Testing based Healthcare";
        break;
      
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Website />} />
      <Route path="/blog_body" element={<BlogBody />} />
      <Route path="/about" element={<About />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/terms_conditions" element={<TermsConditions />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/delete_account" element={<DeleteUser />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/parameters" element={<Parameters />} />
      <Route path="/redirect" element={<Redirect />} />
      <Route path="/reports" element={<Reports />} />
    </Routes>
  );
}
export default App;

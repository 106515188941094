import React, { FunctionComponent,useState, useEffect } from "react";
import styles from "./BlogBody.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer"
import axios from 'axios';
import moment from 'moment'
import parse from 'html-react-parser';

const BlogBody: FunctionComponent = () => {

  const navigate = useNavigate();

  let [tag,setTag] = useState(''); 
  let [read,setRead] = useState(null);
  let [date,setDate] = useState('');
  let [image,setImage] = useState('');
  let [heading,setHeading] = useState('');
  let [body,setBody] = useState('');
  let [showMain,setShowMain] = useState(false);
  const [rCount,setRCount] = useState(100)

  const url = "https://prevealth.com/v1/api/blog/get";
  const headers = {
    'Content-Type':'application/json',
    'x-administrator-token':'reta245'
  }


  const fetchInfo = () => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    return axios.post(url,{order:Number(params.get('order'))},{headers:headers}).then((response) => {
      let mata = response.data.data;
      setTag(mata[0]['tag'])
      setRead(mata[0]['read'])
      setDate(mata[0]['createdAt'])
      setImage(mata[0]['image'])
      setHeading(mata[0]['heading'])
      setBody(mata[0]['body'])
      setShowMain(true)
    })
  }

  useEffect(() => {
    fetchInfo();
    setRCount(100)
  },[rCount])
  let imageP = "/images/blogs/" + image;

  return (
    <div className={styles.website}>
      <Header></Header>
      <div className={styles.main}>
        {showMain == true &&
          <div>
          <div className={styles.tag}>{tag}</div>
          <div className={styles.helper}>
            <span>{moment(date).format('Do MMM, YYYY')}</span>
            <span>{read} min read</span>
          </div>
          <div className={styles.heading}>{heading}</div>
          <img src={imageP} className={styles.mainImage} />
          <div className={styles.body}>
            {parse(body)}
          </div>
        </div>
        }
      </div>
      <Footer></Footer>
    </div>
  );
};

export default BlogBody;

import { FunctionComponent, useState } from "react";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";


const Header: FunctionComponent = () => {

  const navigate = useNavigate();
  let [isDrawer, setIsDrawer] = useState(false);

  function navHome(){
    navigate('/')
  }
  function navPlans(){
    navigate('/plans')
  }
  function navParameters(){
    navigate('/parameters')
  }
  function navBlogs(){
    navigate('/blogs')
  }
  function navAbout(){
    navigate('/about')
  }
  function navWhatsapp(){
    window.open('https://wa.me/919289887339?text=Hi','_blank')
  }
  return (
    <div className={styles.navbar}>
        {isDrawer == false &&
        <div className={styles.drawer} onClick={()=>setIsDrawer(true)}>
          <img src="/icons/drawer.png" />
        </div>
        }
        {isDrawer == true &&
        <div className={styles.drawerCross} onClick={()=>setIsDrawer(false)}>
          <img src="/icons/drawer_cross.png" />
        </div>
        }
        
        <img className={styles.navIcon} alt="" src="/logo_main.png" onClick={navHome} />
        <div className={styles.navbarLinkParent}>
            <div className={styles.navbarLink}>
                <div className={styles.tabs} onClick={navPlans}>Plans</div>
                <div className={styles.tabs} onClick={navParameters}>Report Parameters</div>
                <div className={styles.tabs} onClick={navBlogs}>Blogs</div>
                <div className={styles.tabs} onClick={navAbout}>About Us</div>
                <div className={styles.tabs2} onClick={navWhatsapp}>
                    <img src="/icons/whatsapp.svg" />
                    <span className={styles.span}>Book on WhatsApp</span>
                </div>
            </div>
        </div>
        {isDrawer == true &&
        <div className={styles.mobileDrawer}>
          <div className={styles.navbarLink}>
                <div className={styles.tabs} onClick={navPlans}>Plans</div>
                <div className={styles.tabs} onClick={navParameters}>Report Parameters</div>
                <div className={styles.tabs} onClick={navBlogs}>Blogs</div>
                <div className={styles.tabs} onClick={navAbout}>About Us</div>
                <div className={styles.tabs2} onClick={navWhatsapp}>
                    <img src="/icons/whatsapp.svg" />
                    <span className={styles.span}>Book on WhatsApp</span>
                </div>
            </div>
        </div>
        }
    </div>
  );
};

export default Header;

import { FunctionComponent, useState, useEffect } from "react";
import styles from "./Reports.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer"
import axios from 'axios';


const ContactDiet: FunctionComponent = () => {

  const navigate = useNavigate();
  const url = "https://prevealth.com/v1/api/result/getLink";
  const headers = {
    'Content-Type':'application/json',
    'x-administrator-token':'reta245'
  }
  let [mode,setMode] = useState(''); 
  let [entry,setEntry] = useState('');
  let [rep,setRep] = useState(''); 
  let [cmail,setCmail] = useState(''); 
  let [cnumber,setCnumber] = useState(''); 
  const [rCount,setRCount] = useState(100)



  const fetchInfo = () => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    return axios.post(url,{resultId:params.get('id')},{headers:headers}).then((response) => {
      let mata = response.data.data;
      console.log(mata);
      if(!!mata.userObj.email && mata.userObj.email != ''){
        setMode('email')
        setEntry(mata.userObj.email)
        setRep(mata.result_pdf)
      }else if(!!mata.userObj.number && mata.userObj.number != ''){
        setMode('number')
        setEntry(mata.userObj.number)
        setRep(mata.result_pdf)
      }else{

      }
    })
  }

  useEffect(() => {
    fetchInfo();
    setRCount(100)
  },[rCount])

  const sendInfo = () => {
    if(mode == 'email'){
      if(entry == cmail.trim()){
        const pdfUrl = "https://prevealth.com/assets/" + rep;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "dna_report.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }else{
        alert('Incorrect email!')
      }
    }else if(mode == 'number'){
      if(entry == cnumber.trim()){
        const pdfUrl = "https://prevealth.com/assets/" + rep;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "dna_report.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }else{
        alert('Incorrect number!')
      }
    }else{
      alert('No Report available!')
    }
  }


 
  return (
    <div className={styles.aboutUs}>
      <Header></Header>     
      <div className={styles.titleText2} style={{marginTop:200,marginBottom:-100}}>
        <h5>Prevealth DNA Report</h5>
        <p className={styles.cp}>DNA-Based Preventive Wellness</p>
        <p className={styles.cp2}>Genetic Testing & Personalised Healthcare to help you prevent health risks , know your bodies future & enhance your fitness level</p>
        {mode != '' &&
        <div>
          <div style={{width:'100%'}}>
            <p>Validate your {mode}:</p>
            {mode == 'email' &&
            <input type="email" placeholder="Email" className={styles.cinput} value={cmail} onChange={(e) => setCmail(e.target.value)}></input>
            }
            {mode == 'number' &&
            <input type="tel" placeholder="Number" className={styles.cinput} value={cnumber} onChange={(e) => setCnumber(e.target.value)}></input>
            }
            
          </div>
          <button className={styles.bookBtn} onClick={() => sendInfo()}>Download</button>
        </div>
        }
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ContactDiet;

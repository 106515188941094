import { FunctionComponent } from "react";
import styles from "./About.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer"


const AboutUs: FunctionComponent = () => {

  const navigate = useNavigate();


 
  return (
    <div className={styles.aboutUs}>
      <Header></Header>     
      <div className={styles.banner}>
        <img src="/images/tc.png" />
        <div className={styles.title}>About Us</div>
      </div> 
      <div className={styles.titleText}>
        <div className={styles.lremIpsumDinelagenMonoceptWrapper}>
          <b
            className={styles.lremIpsumDinelagen}
          >{`Revolutionizing Health: Precision DNA-Testing Startup Pioneers Personalized Preventive Healthcare Solutions`}</b>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.value1Parent}>
            <b className={styles.lremIpsumDinelagen}>Precision Empowerment</b>
            <div
              className={styles.lremIpsumDinelagen1}
            >{`Harnessing the power of genetic insights to provide individuals with personalized health recommendations, empowering them to proactively manage and prevent potential health risks.`}</div>
          </div>
          <div className={styles.value1Parent}>
            <b className={styles.lremIpsumDinelagen}>Innovation with Integrity</b>
            <div
              className={styles.lremIpsumDinelagen1}
            >{`Committed to cutting-edge genetic technology while upholding the highest standards of data security and ethical practices, ensuring a trustworthy and confidential experience for our users.`}</div>
          </div>
          <div className={styles.value1Parent}>
            <b className={styles.lremIpsumDinelagen}>Lifelong Wellness Partners</b>
            <div
              className={styles.lremIpsumDinelagen1}
            >{`Beyond diagnostics, we strive to be lifelong partners in our users' wellness journeys, offering continuous support, updates, and evolving solutions based on the latest advancements in genetic research.`}</div>
          </div>
        </div>
      </div>
      <div className={styles.herTitle}>Meet The team</div>
      <div className={styles.titleTextParent}>
        
        <div className={styles.titleText1}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/team/ajay.jpeg"
          />
          <div className={styles.personNameParent}>
            <b className={styles.lremIpsumDinelagen}>Ajay Kadian</b>
          </div>
        </div>
        <div className={styles.titleText1}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/team/sharat.jpeg"
          />
          <div className={styles.personNameParent}>
            <b className={styles.lremIpsumDinelagen}>Sharat Sawhney</b>
          </div>
        </div>
        <div className={styles.titleText1}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/team/vikash.jpeg"
          />
          <div className={styles.personNameParent}>
            <b className={styles.lremIpsumDinelagen}>Vikash Yadav</b>
          </div>
        </div>
        <div className={styles.titleText1}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/team/ehsan.jpeg"
          />
          <div className={styles.personNameParent}>
            <b className={styles.lremIpsumDinelagen}>Ehsan Davran</b>
          </div>
        </div>
      </div>
      <div className={styles.herTitle1}>Meet The Doctors</div>
      <div className={styles.titleTextGroup}>
        <div className={[styles.titleText1,styles.titleTextMain].join(' ')} style={{opacity:0}}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/team/rahila.jpg"
          />
          <div className={styles.personNameParent}>
            <b className={styles.lremIpsumDinelagen}>Doctor Name</b>
            <div
              className={styles.lremIpsumDinelagen4}
            >{`Lörem ipsum dinelagen monoception: tuspesm den fass som sudungen kvasikapp nemigen cookie pronade sedoda, demigen. `}</div>
          </div>
        </div>
        <div className={styles.titleText1}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/team/rahila.jpg"
          />
          <div className={styles.personNameParent}>
            <b className={styles.lremIpsumDinelagen}>Doctor Rahila</b>
            <div
              className={styles.lremIpsumDinelagen4}
            >{`Genomics Scientist. Building genomics solutions: towards personalized medicines`}</div>
          </div>
        </div>
        <div className={[styles.titleText1,styles.titleTextMain].join(' ')} style={{opacity:0}}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/team/rahila.jpg"
          />
          <div className={styles.personNameParent}>
            <b className={styles.lremIpsumDinelagen}>Doctor Name</b>
            <div
              className={styles.lremIpsumDinelagen4}
            >{`Lörem ipsum dinelagen monoception: tuspesm den fass som sudungen kvasikapp nemigen cookie pronade sedoda, demigen. `}</div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AboutUs;

import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Website.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';


const Website: FunctionComponent = () => {

  const navigate = useNavigate();
  let [gata,setGata] = useState<string[]>([]); 
  let [rata,setRata] = useState<string[]>([]);
  const [rCount,setRCount] = useState(100)
  const [faqShow,setFaqShow] = useState(false)
  const [faqText, setFaqText] = useState('See All FAQs')

  const url = "https://prevealth.com/v1/api/faq/getFaqAll";
  const headers = {
    'Content-Type':'application/json',
    'x-administrator-token':'reta245'
  }
  const images = ["/icons/faq1.svg","/icons/faq2.svg","/icons/faq3.svg","/icons/faq4.svg",
  "/icons/faq5.svg","/icons/faq6.svg"]

  function faqToggle(){
    if(faqShow==false){
      setFaqShow(true)
    }else{
      setFaqShow(false)
    }
    if(faqText == 'See All FAQs'){
      setFaqText('See less FAQs')
    }else{
      setFaqText('See All FAQs')
    }
  }

  const fetchInfo = () => {
    return axios.post(url,{},{headers:headers}).then((response) => {
      let mata = response.data.data.sort((a:any,b:any)=>a.order-b.order);
      let jata = Array();
      let kata = Array();
      let nata = Array();
      let pata = Array();
      for(let i=0;i<mata.length;i++){
        if(i<=5){
          kata.push( 
            <div className={styles.faqItem}>
              <img src={images[(mata[i]['order']-1)%6]} />
              <p>{mata[i]['faq']}</p>
              <p>{mata[i]['response']}</p>
            </div>
            );
          if((i-2)%3==0 || i==mata.length-1){
            jata.push(
              <div className={styles.faqFlex}>
                {kata}
              </div>
            )
            kata = Array();
          }
        }else{
          pata.push( 
            <div className={styles.faqItem}>
              <img src={images[(mata[i]['order']-1)%6]} />
              <p>{mata[i]['faq']}</p>
              <p>{mata[i]['response']}</p>
            </div>
            );
          if((i-2)%3==0 || i==mata.length-1){
            nata.push(
              <div className={styles.faqFlex}>
                {pata}
              </div>
            )
            pata = Array();
          }
        }

      }
      setGata(jata)
      setRata(nata)
    })
  }

  useEffect(() => {
    fetchInfo();
    setRCount(100)
  },[rCount])

  function moveToPlayStore(){
    window.open('https://play.google.com/store/apps/details?id=com.sharatsawhney.prevealth','_blank')
  }
  function moveToAppStore(){
    window.open('https://apps.apple.com/us/app/prevealth/id6470473274','_blank')
  }
  function navPlans(){
    navigate('/plans')
  }

  let [image,setImage] = useState('/steps/step1.svg'); 
  let [icon,setIcon] = useState('/steps/ste1.svg');
  let [heading,setHeading] = useState('Book your DNA test and receive your collection kit.');
  let [body,setBody] = useState('Embark on a journey to discover your unique genetic code! Simply book your DNA test and seamlessly receive your personalized collection kit, unlocking a world of insights into your health and ancestry from the comfort of your home.');
  let [isActive1, setIsActive1] = useState(true);
  let [isActive2, setIsActive2] = useState(false);
  let [isActive3, setIsActive3] = useState(false);
  let [isActive4, setIsActive4] = useState(false);
  let [isActive5, setIsActive5] = useState(false);

  function navParameters(){
    navigate('/parameters')
  }



  var settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows:false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var settings2 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots:true,
    speed:500,
    arrows:false
  }
  var settings3 = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots:true,
    speed:500,
    arrows:false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <div className={styles.website}>
      <Header></Header>
      <div className={styles.banner}>
        <img src="/images/home_banner.png" className={styles.homeBanner} />
        <img src="/images/home_banner_mobile.png" className={styles.homeBannerMobile} />
        <div className={styles.title}>
          <p>Book your<br></br> DNA test now</p>
          <p>India's Most Trustworthy and Accurate Results</p>
          <div className={styles.mobileAppStoreBadgeParentHome}>
            <img
              className={styles.mobileAppStoreBadgeHome}
              alt=""
              src="/images/mobile-app-store-badge.svg"
              onClick={moveToPlayStore}
            />
            <img
              className={styles.mobileAppStoreBadge1Home}
              alt=""
              src="/images/mobile-app-store-badge1.svg"
              onClick={moveToAppStore}
            />
          </div>
        </div>
      </div>
      <div className={styles.sliderCover} id="slider1">
        <Slider {...settings}>
          <div className={styles.slider1}>
            <div>
              <img src="/icons/slider1-1.svg" />
            </div>
            <div>
              <p>Eliminate Health Risks</p>
              <p>Proactively address potential health concerns.</p>
            </div>
          </div>
          <div className={styles.slider1}>
            <div>
              <img src="/icons/slider1-2.svg" />
            </div>
            <div>
              <p>Foresee Diseases</p>
              <p>Stay ahead by predicting possible future ailments.</p>
            </div>
          </div>
          <div className={styles.slider1}>
            <div>
              <img src="/icons/slider1-3.svg" />
            </div>
            <div>
              <p>For Every Man</p>
              <p>Discover your body's secrets with a DNA test. Stay proactive. Stay healthy.</p>
            </div>
          </div>
          <div className={styles.slider1}>
            <div>
              <img src="/icons/slider1-4.svg" />
            </div>
            <div>
              <p>For Every Woman</p>
              <p>Protect yourself from potential health threats, including Breast and Ovarian Cancer.</p>
            </div>
          </div>
          <div className={styles.slider1}>
            <div>
              <img src="/icons/slider1-5.svg" />
            </div>
            <div>
              <p>For Child's Growth</p>
              <p>Ensure a healthy start by understanding your child's genetic risks.</p>
            </div>
          </div>
          <div className={styles.slider1}>
            <div>
              <img src="/icons/slider1-6.svg" />
            </div>
            <div>
              <p>For Every Couple</p>
              <p>Understand your heart's language and uncover potential diabetes risks.</p>
            </div>
          </div>
        </Slider>
      </div>
      <div className={styles.secrets}>
        <h2 className={styles.heading}>Know the secrets of fitness & health?</h2>
        <div>
          <div className={styles.secretsFlex}>
            <div className={styles.secretsItem}>
              <img src="/arts/secrets1.svg" />
              <div>
                <p>What is DNA</p>
                <p>DNA to a large extent dictates your overall physical & mental growth. Discover your body's secrets with a DNA test. Stay proactive. Stay healthy.</p>
              </div>
            </div>
            <div className={styles.secretsItem}>
              <img src="/arts/secrets2.svg" />
              <div>
                <p>Diet & Nutrition</p>
                <p>Personalize your nutrition with DNA and body-structure based Diet & Nutrition. Get recommendations from a genetic nutritionist.</p>
              </div>
            </div>
            <div className={styles.secretsItem}>
              <img src="/arts/secrets3.svg" />
              <div>
                <p>Ancestry</p>
                <p>Embark on a journey through time and heritage with our DNA Ancestry service, uncovering the rich tapestry of your roots</p>
              </div>
            </div>
          </div>
          <div className={styles.secretsFlex}>
            <div className={styles.secretsItem}>
              <img src="/arts/secrets4.svg" />
              <div>
                <p>Prevent Diseases</p>
                <p>Empower your health journey by proactively preventing diseases through personalized genetic insights</p>
              </div>
            </div>
            <div className={styles.secretsItem}>
              <img src="/arts/secrets5.svg" />
              <div>
                <p>Weight Loss</p>
                <p>Achieve your weight loss goals with precision—unlock personalized strategies based on your DNA for a tailored approach.</p>
              </div>
            </div>
            <div className={styles.secretsItem}>
              <img src="/arts/secrets6.svg" />
              <div>
                <p>Understand Diabetes and Heart Risks</p>
                <p>Gain proactive control over your health and decode personalized insights.</p>
              </div>
            </div>
          </div>
          <div className={styles.secretsFlex}>
            <div className={styles.secretsItem}>
              <img src="/arts/secrets7.svg" />
              <div>
                <p>Allergies and Chronic Diseases</p>
                <p>Take charge of your well-being—uncover insights into allergies and chronic diseases through personalized DNA analysis.</p>
              </div>
            </div>
            <div className={styles.secretsItem}>
              <img src="/arts/secrets8.svg" />
              <div>
                <p>Foresee Cancer Risks</p>
                <p>Protect yourself from potential health threats, including Breast and Ovarian Cancer.</p>
              </div>
            </div>
            <div className={styles.secretsItem}>
              <img src="/arts/secrets9.svg" />
              <div>
                <p>Women Health</p>
                <p>Elevate women's health with personalized insights, empowering informed decisions and tailored wellness strategies through advanced DNA analysis.</p>
              </div>
            </div>
          </div>
        </div>
        <h2 className={styles.heading2}>Child’s Potential</h2>
        <div>
          <div className={styles.secretsFlex}>
            <div className={styles.secretsItem2}>
              <img src="/arts/secrets10.svg" />
              <div>
                <p>Body Potential (Ideal for fitness and athletics)</p>
              </div>
            </div>
            <div className={styles.secretsItem2}>
              <img src="/arts/secrets11.svg" />
              <div>
                <p>Behaviour</p>
              </div>
            </div>
            <div className={styles.secretsItem2}>
              <img src="/arts/secrets12.svg" />
              <div>
                <p>Cognitive Intelligence (for education and art)</p>
              </div>
            </div>
            <div className={styles.secretsItem2}>
              <img src="/arts/secrets13.svg" />
              <div>
                <p>Future Health Risks & Ancestrial Risks</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button className={styles.plansBtn} onClick={navPlans}>See Our Plans</button>
        </div>
      </div>
      <div className={styles.features}>
        <h2 className={styles.heading3}>Features</h2>
        <div className={styles.featureItem}>
          <div className={styles.featureLeft}>
            <h6>Ancestry Explorer</h6>
            <p>Uncover Your Roots with Precision</p>
            <p>Embark on a journey of self-discovery with our Ancestry Explorer feature. Dive into the depths of your genetic heritage, unraveling the threads of your ancestry with unparalleled precision. From tracing migration patterns to discovering long-lost connections, our app provides an immersive and insightful experience, painting a vivid picture of your family's history.</p>
            <div>
              <button className={styles.plansBtn2} onClick={navPlans}>See Our Plans</button>
            </div>
          </div>
          <div className={styles.featureRight}>
            <img src="/arts/feature1.png"/>
          </div>
        </div>
        <div className={styles.featureItem2}>
          <div className={styles.featureRight2}>
            <img src="/arts/feature2.png" />
          </div>
          <div className={styles.featureLeft2}>
            <h6>Health Pulse</h6>
            <p>Decode Your Health Predispositions</p>
            <p>Introducing HealthPulse, your comprehensive guide to understanding genetic health predispositions. Delve into personalized insights that decode your genetic makeup, revealing potential susceptibilities and strengths. Navigate your well-being journey with knowledge, empowering yourself to make informed lifestyle choices.</p>
            <div>
              <button className={styles.plansBtn2} onClick={navPlans}>See Our Plans</button>
            </div>
          </div>
        </div>
        <div className={styles.featureItem}>
          <div className={styles.featureLeft}>
            <h6>NutriGenix</h6>
            <p>Tailored Diet Recommendations for You</p>
            <p>Unlock the power of NutriGenix, your key to personalized nutrition. Receive bespoke diet recommendations crafted specifically for your genetic profile. Whether it's optimizing macronutrient balance, fine-tuning nutrient absorption, or addressing dietary sensitivities, NutriGenix tailors your diet to align with your genetic predispositions.</p>
            <div>
              <button className={styles.plansBtn2} onClick={navPlans}>See Our Plans</button>
            </div>
          </div>
          <div className={styles.featureRight}>
            <img src="/arts/feature3.png" />
          </div>
        </div>
      </div>
      <div className={styles.steps}>
        <h2 className={styles.heading3}>Our Simple Process</h2>
        <div className={styles.stepsMain}>
          <div className={styles.leftStep}>
            <div className={styles.stepLine}></div>
            <div className={styles.step1} style={isActive1?{'backgroundColor':'#7D01FD','color':'#ffffff','width':'200%','borderRadius':'30px','left':'-20%'}:{}} onClick={() => {setImage('/steps/step1.svg');setIcon('/steps/ste1.svg');setHeading('Book your DNA test and receive your collection kit.');setBody('Embark on a journey to discover your unique genetic code! Simply book your DNA test and seamlessly receive your personalized collection kit, unlocking a world of insights into your health and ancestry from the comfort of your home.');setIsActive1(true);setIsActive2(false);setIsActive3(false);setIsActive4(false);setIsActive5(false);}}>1</div>
            <div className={styles.step2} style={isActive2?{'backgroundColor':'#7D01FD','color':'#ffffff','width':'200%','borderRadius':'30px','left':'-20%'}:{}} onClick={() => {setImage('/steps/step2.svg');setIcon('/steps/ste2.svg');setHeading('Collect your saliva samples using our kit.');setBody('Experience simplicity in DNA testing - effortlessly collect your blood/saliva samples with our user-friendly kit. Our streamlined process ensures ease and precision, bringing the power of genetic insights to your fingertips.');setIsActive1(false);setIsActive2(true);setIsActive3(false);setIsActive4(false);setIsActive5(false);}}>2</div>
            <div className={styles.step3} style={isActive3?{'backgroundColor':'#7D01FD','color':'#ffffff','width':'200%','borderRadius':'30px','left':'-20%'}:{}} onClick={() => {setImage('/steps/step3.svg');setIcon('/steps/ste3.svg');setHeading('Send your samples to our laboratory for precise analysis.');setBody('Elevate your health journey by sending your collected samples to our state-of-the-art laboratory. Our expert team utilizes cutting-edge technology for precise genetic analysis, unveiling personalized insights that empower you to take control of your well-being.');setIsActive1(false);setIsActive2(false);setIsActive3(true);setIsActive4(false);setIsActive5(false);}}>3</div>
            <div className={styles.step4} style={isActive4?{'backgroundColor':'#7D01FD','color':'#ffffff','width':'200%','borderRadius':'30px','left':'-20%'}:{}} onClick={() => {setImage('/steps/step4.svg');setIcon('/steps/ste4.svg');setHeading('Track your progress live on our app.');setBody('Stay in the driver`s seat of your health transformation - effortlessly track your genetic insights in real-time through our intuitive app. Witness your progress unfold, making informed decisions for a healthier, more personalized lifestyle.');setIsActive1(false);setIsActive2(false);setIsActive3(false);setIsActive4(true);setIsActive5(false);}}>4</div>
            <div className={styles.step5} style={isActive5?{'backgroundColor':'#7D01FD','color':'#ffffff','width':'200%','borderRadius':'30px','left':'-20%'}:{}} onClick={() => {setImage('/steps/step5.svg');setIcon('/steps/ste5.svg');setHeading('Receive your personalized report in 2-3 weeks.');setBody('Anticipate the unveiling of your unique health narrative! Receive your comprehensive and personalized DNA insights in just 2-3 weeks, empowering you with valuable information to guide your wellness journey with precision and clarity.');setIsActive1(false);setIsActive2(false);setIsActive3(false);setIsActive4(false);setIsActive5(true);}}>5</div>
          </div>
          <div className={styles.stepImage}>
            <img src={image} />
          </div>
          <div className={styles.stepInfo}>
            <img src={icon} />
            <p>{heading}</p>
            <p>{body}</p>
          </div>
        </div>
      </div>
      {/* <div className={styles.starsParent}>
        <img className={styles.starsIcon} alt="" src="/stars1.svg" />
        <div className={styles.image2Parent}>
          <img className={styles.image2Icon} alt="" src="/image-21@2x.png" />
          <div className={styles.frameParent}>
            <div className={styles.foreseeDiseasesParent}>
              <b className={styles.foreseeDiseases}>Foresee Diseases</b>
              <div className={styles.stayAheadBy}>
                Stay ahead by predicting possible future ailments.
              </div>
            </div>
            <div className={styles.ellipseWrapper}>
              <div className={styles.frameChild} />
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.eliminateHealthRisksParent}>
              <b className={styles.foreseeDiseases}>Eliminate Health Risks</b>
              <div className={styles.stayAheadBy}>
                Proactively address potential health concerns.
              </div>
            </div>
            <div className={styles.ellipseContainer}>
              <div className={styles.frameChild} />
            </div>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.bookYourDnaTestNowParent}>
            <div className={styles.bookYourDna}>Book your DNA test now</div>
            <div className={styles.indiasMostTrustworthy}>
              India's Most Trustworthy and Accurate Results
            </div>
          </div>
          <div className={styles.mobileAppStoreBadgeParent}>
            <img
              className={styles.mobileAppStoreBadge}
              alt=""
              src="/mobile-app-store-badge.svg"
              onClick={moveToPlayStore}
            />
            <img
              className={styles.mobileAppStoreBadge1}
              alt=""
              src="/mobile-app-store-badge1.svg"
              onClick={moveToAppStore}
            />
          </div>
        </div>
      </div> */}
      <div className={styles.titleTextParent}>
        <div className={styles.titleText}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/images/malePic.png"
          />
          <div className={styles.frameDiv}>
            <div className={styles.maleWrapper}>
              <img className={styles.maleIcon} alt="" src="/icons/male.svg" />
            </div>
            <b className={styles.foreseeDiseases}>For Every Man</b>
            <div className={styles.discoverYourBodys}>
              Discover your body's secrets with a DNA test. Stay proactive. Stay
              healthy.
            </div>
          </div>
        </div>
        <div className={styles.titleText1}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/images/femalePic.png"
          />
          <div className={styles.frameDiv}>
            <div className={styles.maleWrapper}>
              <img className={styles.maleIcon} alt="" src="/icons/female.svg" />
            </div>
            <b className={styles.foreseeDiseases}>For Every Woman</b>
            <div className={styles.discoverYourBodys}>
              Protect yourself from potential health threats, including Breast
              and Ovarian Cancer.
            </div>
          </div>
        </div>
        <div className={styles.titleText2}>
          <img
            className={styles.imageFromRawpixelId1255100Icon}
            alt=""
            src="/images/childPic.png"
          />
          <div className={styles.frameDiv}>
            <div className={styles.maleWrapper}>
              <img className={styles.maleIcon} alt="" src="/icons/child.svg" />
            </div>
            <b className={styles.foreseeDiseases}>For Child's Growth</b>
            <div className={styles.discoverYourBodys}>
              Ensure a healthy start by understanding your child's genetic
              risks.
            </div>
          </div>
        </div>
        {/*<div className={styles.navChildright}>
          <img
            className={styles.chevronForwardCircleIcon}
            alt=""
            src="/chevronforwardcircle.svg"
          />
      </div>*/}
      </div>
      <div className={styles.customers} id="slider2">
        <h2 className={styles.heading3}>What Our Customers Say About Us</h2>
        <Slider {...settings2}>
          <div className={styles.slider2}>
            <div className={styles.leftSlider}>
              <img src="/customers/chetna.jpg" className={styles.cImage} />
              <div className={styles.linkedIn}>
                <img src="/icons/clinkedin.svg" onClick={()=>{window.open('https://www.linkedin.com/in/chetna-gandhi-77145889/','_blank')}} />
                <span>Chetna Gandhi</span>
              </div>
            </div>
            <div className={styles.rightSlider}>
              <img src="/icons/quote1.svg" className={styles.quote1} />
              <p>Thanks to Prevealth's DNA testing and counseling, I've embraced a healthier lifestyle tailored to my genetic blueprint. The personalized insights and expert guidance have been a game-changer, guiding me towards optimal nutrition, exercise, and overall well-being. Grateful for this transformative approach to a healthier, happier life.</p>
              <img src="/icons/quote2.svg" className={styles.quote2} />
            </div>
          </div>
          <div className={styles.slider2}>
            <div className={styles.leftSlider}>
              <img src="/customers/jamshad.jpg" className={styles.cImage} />
              <div className={styles.linkedIn}>
                <img src="/icons/clinkedin.svg" onClick={()=>{window.open('https://www.linkedin.com/in/jamshad-khan/','_blank')}} />
                <span>Jamshad Khan</span>
              </div>
            </div>
            <div className={styles.rightSlider}>
              <img src="/icons/quote1.svg" className={styles.quote1} />
              <p>Prevealth's DNA testing and counseling have been instrumental in my preventive health journey. The insights into my genetic predispositions empowered me to proactively address potential risks, enabling me to make informed lifestyle choices for a healthier future. Grateful for this invaluable tool in disease prevention.</p>
              <img src="/icons/quote2.svg" className={styles.quote2} />
            </div>
          </div>
          <div className={styles.slider2}>
            <div className={styles.leftSlider}>
              <img src="/customers/sharat.jpg" className={styles.cImage} />
              <div className={styles.linkedIn}>
                <img src="/icons/clinkedin.svg" onClick={()=>{window.open('https://www.linkedin.com/in/sharatsawhney/','_blank')}} />
                <span>Sharat Sawhney</span>
              </div>
            </div>
            <div className={styles.rightSlider}>
              <img src="/icons/quote1.svg" className={styles.quote1} />
              <p>Prevealth's DNA testing and counseling were a revelation. The personalized insights and expert guidance transformed my approach to health, providing a roadmap tailored to my unique genetic makeup. Highly recommend for anyone seeking a profound impact on their well-being.</p>
              <img src="/icons/quote2.svg" className={styles.quote2} />
            </div>
          </div>
        </Slider>
      </div>
      <div className={styles.accrediations} id="slider3">
        <h2 className={styles.heading3}>Our Partner Accrediations</h2>
        <Slider {...settings3}>
          <div className={styles.slider3}>
            <img src="/accrediations/iso.jpg" />
          </div>
          <div className={styles.slider3}>
            <img src="/accrediations/nabl.jpg" />
          </div>
          <div className={styles.slider3}>
            <img src="/accrediations/erndim.jpg" />
          </div>
          <div className={styles.slider3}>
            <img src="/accrediations/cdc.jpg" />
          </div>
          <div className={styles.slider3}>
            <img src="/accrediations/cap.jpg" />
          </div>
        </Slider>
      </div>
      <div className={styles.params}>
        <h2 className={styles.heading}>Featured Report Parameters</h2>
        <div className={styles.parameters}>
          <div className={styles.paramFlex}>
            <div className={styles.paramItem}>
              <img src="/icons/param1.svg" className={styles.mainIcon} />
              <h6>Diet and Nutrition</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/diet5.svg" />
                <p>Carbohydrate preference</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet1.svg" />
                <p>Fruit Diet Preference</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet2.svg" />
                <p>Mediterranian Diet Effectiveness</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet3.svg" />
                <p>Fat Over-consumption</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet4.svg" />
                <p>Raw Vegetable preference</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/diet6.svg" />
                <p>Over Eating risk</p>
              </div>
              <p className={styles.more} onClick={navParameters}>Explore more parameters</p>
            </div>
            <div className={styles.paramItem}>
              <img src="/icons/param2.svg" className={styles.mainIcon} />
              <h6>Fitness</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/fit1.svg" />
                <p>Aerobic Potential</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit2.svg" />
                <p>Detoxification Ability</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit3.svg" />
                <p>Muscle strength</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit4.svg" />
                <p>Affinity to Sedentary lifestyle</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit5.svg" />
                <p>DNA Damage Repair</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/fit6.svg" />
                <p>Power</p>
              </div>
              <p className={styles.more2} onClick={navParameters}>Explore more parameters</p>
            </div>
            <div className={styles.paramItem}>
              <img src="/icons/param3.svg" className={styles.mainIcon} />
              <h6>Lifestyle</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/life7.svg" />
                <p>Affinity to Sedentary lifestyle</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life8.svg" />
                <p>Likelihood of Fatigue</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life9.svg" />
                <p>Smoking</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life10.svg" />
                <p>Childhood Obesity</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life11.svg" />
                <p>Motivation to Exercise</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/life12.svg" />
                <p>Obesity</p>
              </div>
              <p className={styles.more3} onClick={navParameters}>Explore more parameters</p>
            </div>
          </div>
          <div className={styles.paramFlex2}>
            <div className={styles.paramItem}>
              <img src="/icons/param4.svg" className={styles.mainIcon} />
              <h6>Health Predisposition</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/health1.svg" />
                <p>Calcific Aortic Valve Stenosis</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health8.svg" />
                <p>Type 1 Diabetes</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health9.svg" />
                <p>Type 2 Diabetes</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health10.svg" />
                <p>Breast Cancer</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health11.svg" />
                <p>Ovarian Cancer</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/health15.svg" />
                <p>Cocaine</p>
              </div>
              <p className={styles.more4} onClick={navParameters}>Explore more parameters</p>
            </div>
            <div className={styles.paramItem}>
              <img src="/icons/param5.svg" className={styles.mainIcon} />
              <h6>Personality</h6>
              <div className={styles.paramList}>
                <img src="/icons/params/per2.svg" />
                <p>Numerical Abilities</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per3.svg" />
                <p>General Mental Ability</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per8.svg" />
                <p>Spatial Memory</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per10.svg" />
                <p>Social Interaction Ability</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per15.svg" />
                <p>Anger</p>
              </div>
              <div className={styles.paramList}>
                <img src="/icons/params/per16.svg" />
                <p>Resillience</p>
              </div>
              <p className={styles.more5} onClick={navParameters}>Explore more parameters</p>
            </div>
          </div>
          <div className={styles.paramBtn} onClick={navParameters}>See All Report Parameters</div>
        </div>
      </div>
      <div className={styles.faq}>
        <h2 className={styles.heading3}>Frequently Asked Questions</h2>
        {gata}
        {faqShow == true?rata:<div></div>}
        <p style={{fontSize:20,cursor:'pointer'}} onClick={faqToggle}>{faqText}</p>
      </div>
      <Footer></Footer>
      
     
    </div>
  );
};

export default Website;

import React, { useState } from 'react';

function DeleteAccount() {
    const [email, setEmail] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);

    const handleDelete = () => {
        const confirmDelete = window.confirm('Are you sure you want to delete your account?');
        if (confirmDelete) {
            // Simulate account deletion (you can replace this with actual API call)
            setIsDeleted(true);
        }
    };

    return (
        <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto', textAlign: 'center' }}>
            {!isDeleted ? (
                <>
                    <h2>Delete Account</h2>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ padding: '10px', width: '100%', marginBottom: '20px' }}
                    />
                    <button
                        onClick={handleDelete}
                        disabled={!email}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: 'red',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        Delete Account
                    </button>
                </>
            ) : (
                <h3>Your account associated with {email} has been deleted.</h3>
            )}
        </div>
    );
}

export default DeleteAccount;

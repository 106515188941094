import { FunctionComponent } from "react";
import styles from "./Footer.module.css";
import { useNavigate } from "react-router-dom";


const Footer: FunctionComponent = () => {

  const navigate = useNavigate();

  function navHome(){
    navigate('/')
  }
  function navPlans(){
    navigate('/plans')
  }
  function navParameters(){
    navigate('/parameters')
  }
  function navBlogs(){
    navigate('/blogs')
  }
  function navAbout(){
    navigate('/about')
  }
  function navTC(){
    navigate('/terms_conditions')
  }
  function navPP(){
    navigate('/privacy_policy')
  }
  function navInstagram(){
    window.open('https://www.instagram.com/prevealth/','_blank')
  }
  function navLinkedIn(){
    window.open('https://www.linkedin.com/company/prevealth/','_blank')
  }
  function navFacebook(){
    window.open('https://www.facebook.com/prevealth/','_blank')
  }

  function moveToPlayStore(){
    window.open('https://play.google.com/store/apps/details?id=com.sharatsawhney.prevealth','_blank')
  }
  function moveToAppStore(){
    window.open('https://apps.apple.com/us/app/prevealth/id6470473274','_blank')
  }
  return (
    <div>
      <div className={styles.takeYourPathToPreventiveWParent}>
        <div className={styles.bookYourDna}>
          Take Your Path to Preventive Wellness
        </div>
        <div className={styles.mobileAppStoreBadgeParent}>
          <img
            className={styles.mobileAppStoreBadge}
            alt=""
            src="/images/mobile-app-store-badge.svg"
            onClick={moveToPlayStore}
          />
          <img
            className={styles.mobileAppStoreBadge1}
            alt=""
            src="/images/mobile-app-store-badge1.svg"
            onClick={moveToAppStore}
          />
        </div>
      </div>
      <div className={styles.mainFooter}>
        <div className={styles.info}>
          <img src="/logo_white.png" />
          <p>Have questions or want to learn more? Get in touch with our team today.</p>
          <div>
            <img src="/icons/instagram3.svg" onClick={navInstagram} />
            <img src="/icons/linkedin.svg" onClick={navLinkedIn} />
            <img src="/icons/facebook.svg" onClick={navFacebook} />
          </div>
          <p>contact@prevealth.com</p>
          <p>+91 9289887339</p>
        </div>
        <div className={styles.address}>
          <p className={styles.head}><b>Address</b></p>
          <p>93 U/g/f, Gali No-1</p>
          <p>Sarojni Park Shastri Nagar</p>
          <p>East Delhi</p>
          <p>Delhi, India - 110031</p>
        </div>
        <div className={styles.links}>
          <p className={styles.head}><b>Links</b></p>
          <p onClick={navPlans}>Plans</p>
          <p onClick={navParameters}>Report Parameters</p>
          <p onClick={navBlogs}>Blogs</p>
          <p onClick={navAbout}>About Us</p>
          <p onClick={navTC}>Terms & Conditions</p>
          <p onClick={navPP}>Privacy Policy</p>
        </div>
      </div>
      <div className={styles.copyright}>© 2023 by Prevealth</div>
    </div>
  );
};

export default Footer;
